import React, { Component } from 'react';

import TextArea from './components/TextArea';
import Preview from './components/Preview';
import LetterPool from './components/LetterPool';

import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

import './App.css';

import appLogo from './assets/wiska-logo.png';

class App extends Component {
  constructor(props) {
    super(props);

    // Central state holder
    this.state = {
      text: '',
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.downloadPreview = this.downloadPreview.bind(this);
  }

  onTextChange(value) {
    this.setState({
      text: value
    });
  }

  downloadPreview(evt) {
    evt.stopPropagation();

    const container = document.querySelector('.download-generator .bitmap-container');
    html2canvas(container, {
      onrendered: (canvas) => {
        canvas.toBlob(function(blob) {
          saveAs(blob, 'download.png');
        });
      },
      width: container.scrollWidth
    });
  }

  render() {
    const { text } = this.state;
    return [
      <div className="app" key="app">
        <header className="app-header">
          <img className="app-logo" src={appLogo} alt="WISKA"/>
          <h1 className="app-title">Typo Generator</h1>
        </header>

        <Preview
          text={text}
          onDownload={this.downloadPreview}
        />

        <TextArea
          onChange={this.onTextChange}>
        </TextArea>
      </div>,
      <div className="download-generator" key="download-generator">
        <LetterPool
            text={text}
            mode='full' />
      </div>
    ];
  }
}

export default App;
