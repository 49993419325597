import React, { Component } from 'react';

class TextArea extends Component {
  constructor() {
    super();
    this.onChange = this.onChange.bind(this);
  }

  onChange(evt) {
    this.props.onChange(evt.target.value);
  }

  render() {
    return (
      <textarea
        autoFocus
        placeholder="Text eingeben..."
        className="userInput"
        onChange={this.onChange} >
      </textarea>
    )
  }
};

export default TextArea;