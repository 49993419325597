import React, {Component} from 'react';

import splitter from '../utils/splitter';

// TODO: Dynamic class imports
import Number_0_Letter from '../assets/0.png';
import Number_1_Letter from '../assets/1.png';
import A_Letter from '../assets/a.png';
import AE_Letter from '../assets/ae.png';
import A_Apostroph_Letter from '../assets/a_apostroph.png';
import B_Letter from '../assets/b.png';
import C_Letter from '../assets/c.png';
import D_Letter from '../assets/d.png';
import E_Letter from '../assets/e.png';
import EE_Letter from '../assets/ee.png';
import E_Apostroph_Letter from '../assets/e_apostroph.png';
import F_Letter from '../assets/f.png';
import FF_Letter from '../assets/ff.png';
import G_Letter from '../assets/g.png';
import H_Letter from '../assets/h.png';
import I_Letter from '../assets/i.png';
import I_Apostroph_Letter from '../assets/i_apostroph.png';
import J_Letter from '../assets/j.png';
import K_Letter from '../assets/k.png';
import L_Letter from '../assets/l.png';
import M_Letter from '../assets/m.png';
import N_Letter from '../assets/n.png';
import N_Tilde_Letter from '../assets/n_tilde.png';
import O_Letter from '../assets/o.png';
import OE_Letter from '../assets/oe.png';
import O_Apostroph_Letter from '../assets/o_apostroph.png';
import P_Letter from '../assets/p.png';
import Q_Letter from '../assets/q.png';
import R_Letter from '../assets/r.png';
import S_Letter from '../assets/s.png';
import T_Letter from '../assets/t.png';
import TT_Letter from '../assets/tt.png';
import U_Letter from '../assets/u.png';
import UE_Letter from '../assets/ue.png';
import U_Apostroph_Letter from '../assets/u_apostroph.png';
import V_Letter from '../assets/v.png';
import W_Letter from '../assets/w.png';
import X_Letter from '../assets/x.png';
import Y_Letter from '../assets/y.png';
import Z_Letter from '../assets/z.png';
import Apostroph_Letter from '../assets/_apostroph.png';
import Anfuehrungszeichen_oben_Letter from '../assets/_anfuehrungszeichen_oben.png';
import Anfuehrungszeichen_unten_Letter from '../assets/_anfuehrungszeichen_unten.png';
import Ausrufezeichen_Letter from '../assets/_ausrufezeichen.png';
import Bindestrich_Letter from '../assets/_bindestrich.png';
import Doppelpunkt_Letter from '../assets/_doppelpunkt.png';
import Fragezeichen_Letter from '../assets/_fragezeichen.png';
import Punkt_Letter from '../assets/_punkt.png';
import Komma_Letter from '../assets/_komma.png';

class LetterPool extends Component {
    render() {
        const {text, mode = 'preview'} = this.props;
        const letters = splitter(text);
        const images = letters.map((val, index) => {
            // TODO: Dynamic class import
            let imageComponent;

            switch (val) {
                case '0':
                    imageComponent = Number_0_Letter;
                    break;
                case '1':
                    imageComponent = Number_1_Letter;
                    break;
                case 'A':
                    imageComponent = A_Letter;
                    break;
                case 'Ä':
                    imageComponent = AE_Letter;
                    break;
                case 'À':
                    imageComponent = A_Apostroph_Letter;
                    break;
                case 'Á':
                    imageComponent = A_Apostroph_Letter;
                    break;
                case 'B':
                    imageComponent = B_Letter;
                    break;
                case 'C':
                    imageComponent = C_Letter;
                    break;
                case 'D':
                    imageComponent = D_Letter;
                    break;
                case 'E':
                    imageComponent = E_Letter;
                    break;
                case 'EE':
                    imageComponent = EE_Letter;
                    break;
                case 'È':
                    imageComponent = E_Apostroph_Letter;
                    break;
                case 'É':
                    imageComponent = E_Apostroph_Letter;
                    break;
                case 'F':
                    imageComponent = F_Letter;
                    break;
                case 'FF':
                    imageComponent = FF_Letter;
                    break;
                case 'G':
                    imageComponent = G_Letter;
                    break;
                case 'H':
                    imageComponent = H_Letter;
                    break;
                case 'I':
                    imageComponent = I_Letter;
                    break;
                case 'Ì':
                    imageComponent = I_Apostroph_Letter;
                    break;
                case 'Í':
                    imageComponent = I_Apostroph_Letter;
                    break;
                case 'J':
                    imageComponent = J_Letter;
                    break;
                case 'K':
                    imageComponent = K_Letter;
                    break;
                case 'L':
                    imageComponent = L_Letter;
                    break;
                case 'M':
                    imageComponent = M_Letter;
                    break;
                case 'N':
                    imageComponent = N_Letter;
                    break;
                case 'Ñ':
                    imageComponent = N_Tilde_Letter;
                    break;
                case 'O':
                    imageComponent = O_Letter;
                    break;
                case 'Ö':
                    imageComponent = OE_Letter;
                    break;
                case 'Ò':
                    imageComponent = O_Apostroph_Letter;
                    break;
                case 'Ó':
                    imageComponent = O_Apostroph_Letter;
                    break;
                case 'P':
                    imageComponent = P_Letter;
                    break;
                case 'Q':
                    imageComponent = Q_Letter;
                    break;
                case 'R':
                    imageComponent = R_Letter;
                    break;
                case 'S':
                    imageComponent = S_Letter;
                    break;
                case 'T':
                    imageComponent = T_Letter;
                    break;
                case 'TT':
                    imageComponent = TT_Letter;
                    break;
                case 'U':
                    imageComponent = U_Letter;
                    break;
                case 'Ü':
                    imageComponent = UE_Letter;
                    break;
                case 'Ù':
                    imageComponent = U_Apostroph_Letter;
                    break;
                case 'Ú':
                    imageComponent = U_Apostroph_Letter;
                    break;
                case 'V':
                    imageComponent = V_Letter;
                    break;
                case 'W':
                    imageComponent = W_Letter;
                    break;
                case 'X':
                    imageComponent = X_Letter;
                    break;
                case 'Y':
                    imageComponent = Y_Letter;
                    break;
                case 'Z':
                    imageComponent = Z_Letter;
                    break;
                case '\'':
                    imageComponent = Apostroph_Letter;
                    val = 'apostrophe';
                    break;
                case '`':
                    imageComponent = Apostroph_Letter;
                    val = 'apostrophe';
                    break;
                case '´':
                    imageComponent = Apostroph_Letter;
                    val = 'apostrophe';
                    break;
                case '"':
                    imageComponent = Anfuehrungszeichen_oben_Letter;
                    val = 'quotes';
                    break;
                case '“':
                    imageComponent = Anfuehrungszeichen_oben_Letter;
                    val = 'quotes';
                    break;
                case '”':
                    imageComponent = Anfuehrungszeichen_oben_Letter;
                    val = 'quotes';
                    break;
                case '„':
                    imageComponent = Anfuehrungszeichen_unten_Letter;
                    val = 'quotes-low';
                    break;
                case '!':
                    imageComponent = Ausrufezeichen_Letter;
                    val = 'excl-mark';
                    break;
                case '-':
                    imageComponent = Bindestrich_Letter;
                    val = 'line';
                    break;
                case ':':
                    imageComponent = Doppelpunkt_Letter;
                    val = 'points';
                    break;
                case '?':
                    imageComponent = Fragezeichen_Letter;
                    val = 'question';
                    break;
                case '.':
                    imageComponent = Punkt_Letter;
                    val = 'point';
                    break;
                case ',':
                    imageComponent = Komma_Letter;
                    val = 'comma';
                    break;
                case ' ':
                    val = ' ';
                    break;
                case '\n':
                    val = '\n';
                    break;
                default:
                    imageComponent = '';
                    break;
            }

            if (val === ' ') {
                return <span className="letter-space" key={index}> </span>;
            } else if (val === '\n') {
                return <br key={index}/>;
            }

            return <img
                src={imageComponent}
                key={index}
                alt={val}
                className={`letter-${val}`}/>
        });

        return (
            <div
                className={`bitmap-container mode-${mode}`}>
                {images}
            </div>
        );
    }
}

export default LetterPool;
