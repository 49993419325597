import React, { Component } from 'react';

import LetterPool from './LetterPool';

class Preview extends Component {
  componentDidUpdate() {
    // Always scroll to the bottom on updates to the text
    this.container.scrollTop = 9999;
  }

  render() {
    const { text = '', onDownload } = this.props;
    return (
      <div className="userPreview">
        <div
          className="letterPoolContainer"
          ref={(container) => this.container = container}>
          <LetterPool
            text={text}
            mode='preview' />
        </div>
        <button className="download" onClick={onDownload}>Download</button>
      </div>
    );
  }
}

export default Preview;