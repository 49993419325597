
export default function splitter(s) {
  if (s === '') {
    return [];
  }
  let input = s.toUpperCase();
  let a = [];
  let regexp = /EE|FF|TT|[0-1A-ZÄÜÖÀÁÈÉÌÍÑÒÓÙÚ"“”„',.:?!-]|\s/g;
  let m = regexp.exec(input);
  while (m) {
    a.push(m[0]);
    m = regexp.exec(input);
  }
  return a;
}
